interface Img {
    id: number;
    href: string;
    alt: string;
}

export const ImagesBrands: Img[] = [
    {
        id: 0,
        href: '../../logos/3m.webp',
        alt: '3M - oklejanie samochodów folią 3M WrapTown, zmiana koloru auta, dechroming, ochrona lakieru',
    },
    {
        id: 1,
        href: '../../logos/avery.webp',
        alt: 'Avery - oklejanie samochodów folią Avery WrapTown, zmiana koloru auta, dechroming, ochrona lakieru',
    },
    {
        id: 2,
        href: '../../logos/llumar.webp',
        alt: 'Llumar - oklejanie samochodów folią Llumar WrapTown, zmiana koloru auta, dechroming, ochrona lakieru',
    },
    {
        id: 3,
        href: '../../logos/oracal.webp',
        alt: 'Oracal - oklejanie samochodów folią Oracal WrapTown, zmiana koloru auta, dechroming, ochrona lakieru',
    },
    {
        id: 4,
        href: '../../logos/teckwrap.webp',
        alt: 'TeckWrap - oklejanie samochodów folią TeckWrap WrapTown, zmiana koloru auta, dechroming, ochrona lakieru',
    },
];

export const ImagesAudiA5: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a5/1.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 1,
        href: '../../projects/audi-a5/2.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 2,
        href: '../../projects/audi-a5/3.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 3,
        href: '../../projects/audi-a5/4.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 4,
        href: '../../projects/audi-a5/5.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 5,
        href: '../../projects/audi-a5/6.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 6,
        href: '../../projects/audi-a5/7.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 7,
        href: '../../projects/audi-a5/8.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 8,
        href: '../../projects/audi-a5/9.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
    {
        id: 9,
        href: '../../projects/audi-a5/10.webp',
        alt: 'Dechroming Audi A5 Quattro - WrapTown - Profesjonalne oklejanie aut Wielkopolska',
    },
];

export const ImagesAudiA6Kombi: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a6kombi/1.webp',
        alt: 'Zmiana koloru auta Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-a6kombi/2.webp',
        alt: 'Zmiana koloru auta Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-a6kombi/3.webp',
        alt: 'Zmiana koloru auta Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-a6kombi/4.webp',
        alt: 'Zmiana koloru auta Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/audi-a6kombi/5.webp',
        alt: 'Zmiana koloru auta Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/audi-a6kombi/6.webp',
        alt: 'Zmiana koloru auta Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesMaserattiGhibli: Img[] = [
    {
        id: 0,
        href: '../../projects/maseratti/1.webp',
        alt: 'Zmiana koloru auta Maseratti Ghibli - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/maseratti/2.webp',
        alt: 'Zmiana koloru auta Maseratti Ghibli - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/maseratti/3.webp',
        alt: 'Zmiana koloru auta Maseratti Ghibli - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/maseratti/4.webp',
        alt: 'Zmiana koloru auta Maseratti Ghibli - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/maseratti/5.webp',
        alt: 'Zmiana koloru auta Maseratti Ghibli - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/maseratti/6.webp',
        alt: 'Zmiana koloru auta Maseratti Ghibli - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVwTouran: Img[] = [
    {
        id: 0,
        href: '../../projects/vw-touran/1.webp',
        alt: 'Zmiana koloru auta Volkswagen Touran - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/vw-touran/2.webp',
        alt: 'Zmiana koloru auta Volkswagen Touran - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/vw-touran/3.webp',
        alt: 'Zmiana koloru auta Volkswagen Touran - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/vw-touran/4.webp',
        alt: 'Zmiana koloru auta Volkswagen Touran - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/vw-touran/5.webp',
        alt: 'Zmiana koloru auta Volkswagen Touran - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/vw-touran/6.webp',
        alt: 'Zmiana koloru auta Volkswagen Touran - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesAudiA6Blue: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a6/1.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-a6/2.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-a6/3.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-a6/4.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesAudiA6Gray: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a6-2/1.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-a6-2/2.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-a6-2/3.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-a6-2/4.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/audi-a6-2/5.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/audi-a6-2/6.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/audi-a6-2/7.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/audi-a6-2/8.webp',
        alt: 'Zmiana koloru auta Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesHyundaiTucson: Img[] = [
    {
        id: 0,
        href: '../../projects/hyundai/1.webp',
        alt: 'Ochrona lakieru Hyundai Tucson - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/hyundai/2.webp',
        alt: 'Ochrona lakieru Hyundai Tucson - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/hyundai/3.webp',
        alt: 'Ochrona lakieru Hyundai Tucson - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/hyundai/4.webp',
        alt: 'Ochrona lakieru Hyundai Tucson - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/hyundai/5.webp',
        alt: 'Ochrona lakieru Hyundai Tucson - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/hyundai/6.webp',
        alt: 'Ochrona lakieru Hyundai Tucson - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/hyundai/7.webp',
        alt: 'Ochrona lakieru Hyundai Tucson - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesAudiA5Kombi: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a5-kombi/1.webp',
        alt: 'Dechroming Audi A5 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-a5-kombi/2.webp',
        alt: 'Dechroming Audi A5 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-a5-kombi/3.webp',
        alt: 'Dechroming Audi A5 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-a5-kombi/4.webp',
        alt: 'Dechroming Audi A5 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/audi-a5-kombi/5.webp',
        alt: 'Dechroming Audi A5 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/audi-a5-kombi/6.webp',
        alt: 'Dechroming Audi A5 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/audi-a5-kombi/7.webp',
        alt: 'Dechroming Audi A5 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesOpelVivaro: Img[] = [
    {
        id: 0,
        href: '../../projects/opel-vivaro/1.webp',
        alt: 'Naklejenie pasów kolorową folią Opel Vivaro - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/opel-vivaro/2.webp',
        alt: 'Naklejenie pasów kolorową folią Opel Vivaro - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/opel-vivaro/3.webp',
        alt: 'Naklejenie pasów kolorową folią Opel Vivaro - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/opel-vivaro/4.webp',
        alt: 'Naklejenie pasów kolorową folią Opel Vivaro - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/opel-vivaro/5.webp',
        alt: 'Naklejenie pasów kolorową folią Opel Vivaro - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/opel-vivaro/6.webp',
        alt: 'Naklejenie pasów kolorową folią Opel Vivaro - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesSkodaKamiq: Img[] = [
    {
        id: 0,
        href: '../../projects/skoda-kamiq/1.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Kamiq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/skoda-kamiq/2.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Kamiq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/skoda-kamiq/3.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Kamiq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/skoda-kamiq/4.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Kamiq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/skoda-kamiq/5.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Kamiq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/skoda-kamiq/6.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Kamiq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesMercedesAMGA35: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-a/1.webp',
        alt: 'Zmiana koloru dechroming Mercedes AMG A35 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-a/2.webp',
        alt: 'Zmiana koloru dechroming Mercedes AMG A35 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-a/3.webp',
        alt: 'Zmiana koloru dechroming Mercedes AMG A35 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-a/4.webp',
        alt: 'Zmiana koloru dechroming Mercedes AMG A35 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-a/5.webp',
        alt: 'Zmiana koloru dechroming Mercedes AMG A35 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-a/6.webp',
        alt: 'Zmiana koloru dechroming Mercedes AMG A35 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/mercedes-a/7.webp',
        alt: 'Zmiana koloru dechroming Mercedes AMG A35 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMW750i: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-750i/1.webp',
        alt: 'Ochrona lakieru BMW 750i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-750i/2.webp',
        alt: 'Ochrona lakieru BMW 750i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-750i/3.webp',
        alt: 'Ochrona lakieru BMW 750i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-750i/4.webp',
        alt: 'Ochrona lakieru BMW 750i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWX6: Img[] = [
    {
        id: 0,
        href: '../../projects/bmwx6/1.webp',
        alt: 'Ochrona lakieru BMW X6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmwx6/2.webp',
        alt: 'Ochrona lakieru BMW X6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmwx6/3.webp',
        alt: 'Ochrona lakieru BMW X6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmwx6/4.webp',
        alt: 'Ochrona lakieru BMW X6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmwx6/5.webp',
        alt: 'Ochrona lakieru BMW X6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMW330DE92: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-explozja/1.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-explozja/2.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-explozja/3.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-explozja/4.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-explozja/5.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-explozja/6.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/bmw-explozja/7.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/bmw-explozja/8.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 8,
        href: '../../projects/bmw-explozja/9.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 9,
        href: '../../projects/bmw-explozja/10.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 10,
        href: '../../projects/bmw-explozja/11.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 11,
        href: '../../projects/bmw-explozja/12.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 12,
        href: '../../projects/bmw-explozja/13.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 13,
        href: '../../projects/bmw-explozja/14.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 14,
        href: '../../projects/bmw-explozja/15.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 15,
        href: '../../projects/bmw-explozja/16.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 16,
        href: '../../projects/bmw-explozja/17.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 17,
        href: '../../projects/bmw-explozja/18.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 18,
        href: '../../projects/bmw-explozja/19.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 19,
        href: '../../projects/bmw-explozja/20.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 20,
        href: '../../projects/bmw-explozja/21.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 21,
        href: '../../projects/bmw-explozja/22.webp',
        alt: 'Zmiana koloru auta BMW 330D E92 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVolvoV60: Img[] = [
    {
        id: 0,
        href: '../../projects/volvo-v60/1.webp',
        alt: 'Dechroming Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/volvo-v60/2.webp',
        alt: 'Dechroming Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/volvo-v60/3.webp',
        alt: 'Dechroming Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/volvo-v60/4.webp',
        alt: 'Dechroming Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/volvo-v60/5.webp',
        alt: 'Dechroming Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesAudiA6Kombi3: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a6-3/1.webp',
        alt: 'Dechroming Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-a6-3/2.webp',
        alt: 'Dechroming Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-a6-3/3.webp',
        alt: 'Dechroming Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-a6-3/4.webp',
        alt: 'Dechroming Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/audi-a6-3/5.webp',
        alt: 'Dechroming Audi A6 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVolvoV602: Img[] = [
    {
        id: 0,
        href: '../../projects/volvo-v60-2/1.webp',
        alt: 'Ochrona lakieru Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/volvo-v60-2/2.webp',
        alt: 'Ochrona lakieru Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/volvo-v60-2/3.webp',
        alt: 'Ochrona lakieru Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/volvo-v60-2/4.webp',
        alt: 'Ochrona lakieru Volvo V60- WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/volvo-v60-2/5.webp',
        alt: 'Ochrona lakieru Volvo V60- WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/volvo-v60-2/6.webp',
        alt: 'Ochrona lakieru Volvo V60 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesAudiQ5: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-q5/1.webp',
        alt: 'Zmiana koloru auta Audi Q5 Szara folia 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-q5/2.webp',
        alt: 'Zmiana koloru auta Audi Q5 Szara folia 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-q5/3.webp',
        alt: 'Zmiana koloru auta Audi Q5 Szara folia 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-q5/4.webp',
        alt: 'Zmiana koloru auta Audi Q5 Szara folia 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/audi-q5/5.webp',
        alt: 'Zmiana koloru auta Audi Q5 Szara folia 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/audi-q5/6.webp',
        alt: 'Zmiana koloru auta Audi Q5 Szara folia 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/audi-q5/7.webp',
        alt: 'Zmiana koloru auta Audi Q5 Szara folia 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesMercedesGL: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-gl50/1.webp',
        alt: 'Zmiana koloru auta Mercedes GL Czarny Mat - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-gl50/2.webp',
        alt: 'Zmiana koloru auta Mercedes GL Czarny Mat - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-gl50/3.webp',
        alt: 'Zmiana koloru auta Mercedes GL Czarny Mat 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-gl50/4.webp',
        alt: 'Zmiana koloru auta Mercedes GL Czarny Mat 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-gl50/5.webp',
        alt: 'Zmiana koloru auta Mercedes GL Czarny Mat 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-gl50/6.webp',
        alt: 'Zmiana koloru auta Mercedes GL Czarny Mat 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/mercedes-gl50/7.webp',
        alt: 'Zmiana koloru auta Mercedes GL Czarny Mat 3M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVwTiguan: Img[] = [
    {
        id: 0,
        href: '../../projects/vw-tiguan/1.webp',
        alt: 'Pełny dechroming i przyciemnienie tylnich lamp Volkswagen Tiguan - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/vw-tiguan/2.webp',
        alt: 'Pełny dechroming i przyciemnienie tylnich lamp Volkswagen Tiguan - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/vw-tiguan/3.webp',
        alt: 'Pełny dechroming i przyciemnienie tylnich lamp Volkswagen Tiguan - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/vw-tiguan/4.webp',
        alt: 'Pełny dechroming i przyciemnienie tylnich lamp Volkswagen Tiguan - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/vw-tiguan/5.webp',
        alt: 'Pełny dechroming i przyciemnienie tylnich lamp Volkswagen Tiguan - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWM235i: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-m235i/1.webp',
        alt: 'Ochrona i przyciemnianie lamp BMW M235i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projectsbmw-m235i/2.webp',
        alt: 'Ochrona i przyciemnianie lamp BMW M235i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-m235i/3.webp',
        alt: 'Ochrona i przyciemnianie lamp BMW M235i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-m235i/4.webp',
        alt: 'Ochrona i przyciemnianie lamp BMW M235i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMW340i: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-340i/1.webp',
        alt: 'Dechroming i przyciemnianie lamp BMW340i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projectsbmw-340i/2.webp',
        alt: 'Dechroming i przyciemnianie lamp BMW340i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-340i/3.webp',
        alt: 'Dechroming i przyciemnianie lamp BMW340i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-340i/4.webp',
        alt: 'Dechroming i przyciemnianie lamp BMW340i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-340i/5.webp',
        alt: 'Dechroming i przyciemnianie lamp BMW340i - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVWTCross: Img[] = [
    {
        id: 0,
        href: '../../projects/vw-tcross/1.webp',
        alt: 'Ochrona lakieru nowy Volkswagen T-Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/vw-tcross/2.webp',
        alt: 'Ochrona lakieru nowy Volkswagen T-Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/vw-tcross/3.webp',
        alt: 'Ochrona lakieru nowy Volkswagen T-Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesJeepGrandCherokee: Img[] = [
    {
        id: 0,
        href: '../../projects/jeep-grand-cherokee/1.webp',
        alt: 'Dechroming Jeep Grand Cherokee - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/jeep-grand-cherokee/2.webp',
        alt: 'Dechroming Jeep Grand Cherokee - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/jeep-grand-cherokee/3.webp',
        alt: 'Dechroming Jeep Grand Cherokee - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/jeep-grand-cherokee/4.webp',
        alt: 'Dechroming Jeep Grand Cherokee - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/jeep-grand-cherokee/5.webp',
        alt: 'Dechroming Jeep Grand Cherokee - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/jeep-grand-cherokee/6.webp',
        alt: 'Dechroming Jeep Grand Cherokee - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesIsuzuDMax: Img[] = [
    {
        id: 0,
        href: '../../projects/isuzu-dmax/1.webp',
        alt: 'Dechroming Isuzu D-Max - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/isuzu-dmax/2.webp',
        alt: 'Dechroming Isuzu D-Max - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/isuzu-dmax/3.webp',
        alt: 'Dechroming Isuzu D-Max - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/isuzu-dmax/4.webp',
        alt: 'Dechroming Isuzu D-Max - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/isuzu-dmax/5.webp',
        alt: 'Dechroming Isuzu D-Max - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/isuzu-dmax/6.webp',
        alt: 'Dechroming Isuzu D-Max - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/isuzu-dmax/7.webp',
        alt: 'Dechroming Isuzu D-Max - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVolvoXC90: Img[] = [
    {
        id: 0,
        href: '../../projects/volvo-xc90/1.webp',
        alt: 'Ochrona lakieru Volvo XC90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/volvo-xc90/2.webp',
        alt: 'Ochrona lakieru Volvo XC90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/volvo-xc90/3.webp',
        alt: 'Ochrona lakieru Volvo XC90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/volvo-xc90/4.webp',
        alt: 'Ochrona lakieru Volvo XC90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/volvo-xc90/5.webp',
        alt: 'Ochrona lakieru Volvo XC90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/volvo-xc90/6.webp',
        alt: 'Ochrona lakieru Volvo XC90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesRenaultTrafic: Img[] = [
    {
        id: 0,
        href: '../../projects/renault-trafic/1.webp',
        alt: 'Ochrona lakieru Renault Trafic - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/renault-trafic/2.webp',
        alt: 'Ochrona lakieru Renault Trafic - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/renault-trafic/3.webp',
        alt: 'Ochrona lakieru Renault Trafic - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/renault-trafic/4.webp',
        alt: 'Ochrona lakieru Renault Trafic - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/renault-trafic/5.webp',
        alt: 'Ochrona lakieru Renault Trafic - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/renault-trafic/6.webp',
        alt: 'Ochrona lakieru Renault Trafic - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/renault-trafic/7.webp',
        alt: 'Ochrona lakieru Renault Trafic - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVwCrafter: Img[] = [
    {
        id: 0,
        href: '../../projects/vw-crafter/1.webp',
        alt: 'Ochrona lakieru Volkswagen Crafter - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/vw-crafter/2.webp',
        alt: 'Ochrona lakieru Volkswagen Crafter - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/vw-crafter/3.webp',
        alt: 'Ochrona lakieru Volkswagen Crafter - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/vw-crafter/4.webp',
        alt: 'Ochrona lakieru Volkswagen Crafter - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesRangeRoverEvoque: Img[] = [
    {
        id: 0,
        href: '../../projects/range-rover-evoque/1.webp',
        alt: 'Ochrona lakieru Range Rover Evoque - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/range-rover-evoque/2.webp',
        alt: 'Ochrona lakieru Range Rover Evoque - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/range-rover-evoque/3.webp',
        alt: 'Ochrona lakieru Range Rover Evoque - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/range-rover-evoque/4.webp',
        alt: 'Ochrona lakieru Range Rover Evoque - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVolvoS90: Img[] = [
    {
        id: 0,
        href: '../../projects/volvo-s90/1.webp',
        alt: 'Ochrona lakieru Volvo S90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/volvo-s90/2.webp',
        alt: 'Ochrona lakieru Volvo S90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/volvo-s90/3.webp',
        alt: 'Ochrona lakieru Volvo S90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/volvo-s90/4.webp',
        alt: 'Ochrona lakieru Volvo S90 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesPorsche911: Img[] = [
    {
        id: 0,
        href: '../../projects/porsche-911/1.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/porsche-911/2.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/porsche-911/3.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/porsche-911/4.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/porsche-911/5.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/porsche-911/6.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/porsche-911/7.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/porsche-911/8.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 8,
        href: '../../projects/porsche-911/9.webp',
        alt: 'Ochrona lakieru Porsche 911 997 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesAudiA6Kombi4: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a6-4/1.webp',
        alt: 'Dechroming Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-a6-4/2.webp',
        alt: 'Dechroming Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-a6-4/3.webp',
        alt: 'Dechroming Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-a6-4/4.webp',
        alt: 'Dechroming Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/audi-a6-4/5.webp',
        alt: 'Dechroming Audi A6 Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWM2: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-m2/1.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-m2/2.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-m2/3.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-m2/4.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-m2/5.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-m2/6.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/bmw-m2/7.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/bmw-m2/8.webp',
        alt: 'Ochrona lakieru BMW M2 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesMercedesV300d: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-v300d/1.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes V 300d - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-v300d/2.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes V 300d - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-v300d/3.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes V 300d - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-v300d/4.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes V 300d - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-v300d/5.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes V 300d - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-v300d/6.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes V 300d - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    }
];

export const ImagesMercedesSW221: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-s-w221/1.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-s-w221/2.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-s-w221/3.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-s-w221/4.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-s-w221/5.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-s-w221/6.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/mercedes-s-w221/7.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/mercedes-s-w221/8.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 8,
        href: '../../projects/mercedes-s-w221/9.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 9,
        href: '../../projects/mercedes-s-w221/10.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 10,
        href: '../../projects/mercedes-s-w221/11.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 11,
        href: '../../projects/mercedes-s-w221/12.webp',
        alt: 'Zmiana koloru auta Mercedes S klasa W221 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    }
];

export const ImagesToyotaYarisCross: Img[] = [
    {
        id: 0,
        href: '../../projects/toyota-yaris-cross/1.webp',
        alt: 'Ochrona lakieru PPF Toyota Yaris Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/toyota-yaris-cross/2.webp',
        alt: 'Ochrona lakieru PPF Toyota Yaris Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/toyota-yaris-cross/3.webp',
        alt: 'Ochrona lakieru PPF Toyota Yaris Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/toyota-yaris-cross/4.webp',
        alt: 'Ochrona lakieru PPF Toyota Yaris Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/toyota-yaris-cross/5.webp',
        alt: 'Ochrona lakieru PPF Toyota Yaris Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/toyota-yaris-cross/6.webp',
        alt: 'Ochrona lakieru PPF Toyota Yaris Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    }
];

export const ImagesToyotaCorollaTs: Img[] = [
    {
        id: 0,
        href: '../../projects/toyota-corolla-ts/1.webp',
        alt: 'Ochrona lakieru PPF Toyota Corolla TS Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/toyota-corolla-ts/2.webp',
        alt: 'Ochrona lakieru PPF Toyota Corolla TS Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/toyota-corolla-ts/3.webp',
        alt: 'Ochrona lakieru PPF Toyota Corolla TS Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/toyota-corolla-ts/4.webp',
        alt: 'Ochrona lakieru PPF Toyota Corolla TS Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/toyota-corolla-ts/5.webp',
        alt: 'Ochrona lakieru PPF Toyota Corolla TS Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/toyota-corolla-ts/6.webp',
        alt: 'Ochrona lakieru PPF Toyota Corolla TS Kombi - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    }
];

export const ImagesBMWX5M: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-x5m/1.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-x5m/2.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-x5m/3.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-x5m/4.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-x5m/5.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-x5m/6.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/bmw-x5m/7.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/bmw-x5m/8.webp',
        alt: 'Ochrona lakieru PPF BMW X5 M - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWZ3: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-z3/1.webp',
        alt: 'Zmiana koloru auta BMW Z3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-z3/2.webp',
        alt: 'Zmiana koloru auta BMW Z3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-z3/3.webp',
        alt: 'Zmiana koloru auta BMW Z3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-z3/4.webp',
        alt: 'Zmiana koloru auta BMW Z3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-z3/5.webp',
        alt: 'Zmiana koloru auta BMW Z3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-z3/6.webp',
        alt: 'Zmiana koloru auta BMW Z3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    }
];

export const ImagesMercedesGT43: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-gt43/1.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes GT 43 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-gt43/2.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes GT 43 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-gt43/3.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes GT 43 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-gt43/4.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes GT 43 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-gt43/5.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes GT 43 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-gt43/6.webp',
        alt: 'Ochrona lakieru i dechroming Mercedes GT 43 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    }
];

export const ImagesAudiQ8: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-q8/1.webp',
        alt: 'Dechroming i ochrona lamp Audi Q8 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-q8/2.webp',
        alt: 'Dechroming i ochrona lamp Audi Q8 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-q8/3.webp',
        alt: 'Dechroming i ochrona lamp Audi Q8 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-q8/4.webp',
        alt: 'Dechroming i ochrona lamp Audi Q8 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/audi-q8/5.webp',
        alt: 'Dechroming i ochrona lamp Audi Q8 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesVWPassat: Img[] = [
    {
        id: 0,
        href: '../../projects/volkswagen-passat/1.webp',
        alt: 'Całkowita zmiana koloru auta Volkswagen Passat oraz zabezpieczenie i przyciemnienie lamp folią PPF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/volkswagen-passat/2.webp',
        alt: 'Całkowita zmiana koloru auta Volkswagen Passat oraz zabezpieczenie i przyciemnienie lamp folią PPF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/volkswagen-passat/3.webp',
        alt: 'Całkowita zmiana koloru auta Volkswagen Passat oraz zabezpieczenie i przyciemnienie lamp folią PPF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/volkswagen-passat/4.webp',
        alt: 'Całkowita zmiana koloru auta Volkswagen Passat oraz zabezpieczenie i przyciemnienie lamp folią PPF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/volkswagen-passat/5.webp',
        alt: 'Całkowita zmiana koloru auta Volkswagen Passat oraz zabezpieczenie i przyciemnienie lamp folią PPF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/volkswagen-passat/6.webp',
        alt: 'Całkowita zmiana koloru auta Volkswagen Passat oraz zabezpieczenie i przyciemnienie lamp folią PPF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesToyotaCorollaCross: Img[] = [
    {
        id: 0,
        href: '../../projects/toyota-corolla-cross/1.webp',
        alt: 'Ochrona lakieru folią PPF Toyota Corolla Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/toyota-corolla-cross/2.webp',
        alt: 'Ochrona lakieru folią PPF Toyota Corolla Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/toyota-corolla-cross/3.webp',
        alt: 'Ochrona lakieru folią PPF Toyota Corolla Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/toyota-corolla-cross/4.webp',
        alt: 'Ochrona lakieru folią PPF Toyota Corolla Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/toyota-corolla-cross/5.webp',
        alt: 'Ochrona lakieru folią PPF Toyota Corolla Cross - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesSkodaOctavia: Img[] = [
    {
        id: 0,
        href: '../../projects/skoda-octavia/1.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Octavia - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/skoda-octavia/2.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Octavia - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/skoda-octavia/3.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Octavia - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/skoda-octavia/4.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Octavia - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/skoda-octavia/5.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Octavia - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/skoda-octavia/6.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Octavia - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/skoda-octavia/7.webp',
        alt: 'Ochrona lakieru folią PPF Skoda Octavia - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesMercedesGLE450d: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-gle-450d/1.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-gle-450d/2.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-gle-450d/3.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-gle-450d/4.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-gle-450d/5.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-gle-450d/6.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/mercedes-gle-450d/7.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/mercedes-gle-450d/8.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 8,
        href: '../../projects/mercedes-gle-450d/9.jpg',
        alt: 'Zmiana koloru auta Mercedes GLE 450d folią KPMF - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMW5: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-5/1.jpg',
        alt: 'Ochrona lakieru nowe BMW serii 5 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-5/2.jpg',
        alt: 'Ochrona lakieru nowe BMW serii 5 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-5/3.jpg',
        alt: 'Ochrona lakieru nowe BMW serii 5 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-5/4.jpg',
        alt: 'Ochrona lakieru nowe BMW serii 5 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-5/5.jpg',
        alt: 'Ochrona lakieru nowe BMW serii 5 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-5/6.jpg',
        alt: 'Ochrona lakieru nowe BMW serii 5 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/bmw-5/7.jpg',
        alt: 'Ochrona lakieru nowe BMW serii 5 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWX5XDRIVE50E: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-x5-xdrive50e/1.jpg',
        alt: 'Ochrona lakieru nowe BMW X5 xDrive50e 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-x5-xdrive50e/2.jpg',
        alt: 'Ochrona lakieru nowe BMW X5 xDrive50e 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-x5-xdrive50e/3.jpg',
        alt: 'Ochrona lakieru nowe BMW X5 xDrive50e 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-x5-xdrive50e/4.jpg',
        alt: 'Ochrona lakieru nowe BMW X5 xDrive50e 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-x5-xdrive50e/5.jpg',
        alt: 'Ochrona lakieru nowe BMW X5 xDrive50e 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-x5-xdrive50e/6.jpg',
        alt: 'Ochrona lakieru nowe BMW X5 xDrive50e 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/bmw-x5-xdrive50e/7.jpg',
        alt: 'Ochrona lakieru nowe BMW X5 xDrive50e 2024 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesMERCEDESGLC220D: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-glc-220d/1.jpg',
        alt: 'Ochrona lakieru Mercedes GLC 220d folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-glc-220d/2.jpg',
        alt: 'Ochrona lakieru Mercedes GLC 220d folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-glc-220d/3.jpg',
        alt: 'Ochrona lakieru Mercedes GLC 220d folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-glc-220d/4.jpg',
        alt: 'Ochrona lakieru Mercedes GLC 220d folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-glc-220d/5.jpg',
        alt: 'Ochrona lakieru Mercedes GLC 220d folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-glc-220d/6.jpg',
        alt: 'Ochrona lakieru Mercedes GLC 220d folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesAudiA3: Img[] = [
    {
        id: 0,
        href: '../../projects/audi-a3/1.jpg',
        alt: 'Zmiana koloru auta Audi A3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/audi-a3/2.jpg',
        alt: 'Zmiana koloru auta Audi A3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/audi-a3/3.jpg',
        alt: 'Zmiana koloru auta Audi A3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/audi-a3/4.jpg',
        alt: 'Zmiana koloru auta Audi A3 - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWX7: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-x7/1.jpg',
        alt: 'Ochrona lakieru BMW X7 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-x7/2.jpg',
        alt: 'Ochrona lakieru BMW X7 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-x7/3.jpg',
        alt: 'Ochrona lakieru BMW X7 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-x7/4.jpg',
        alt: 'Ochrona lakieru BMW X7 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-x7/5.jpg',
        alt: 'Ochrona lakieru BMW X7 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-x7/6.jpg',
        alt: 'Ochrona lakieru BMW X7 folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWM8: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-m8/1.jpg',
        alt: 'Ochrona lakieru BMW M8 Competition folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-m8/2.jpg',
        alt: 'Ochrona lakieru BMW M8 Competition folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-m8/3.jpg',
        alt: 'Ochrona lakieru BMW M8 Competition folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-m8/4.jpg',
        alt: 'Ochrona lakieru BMW M8 Competition folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-m8/5.jpg',
        alt: 'Ochrona lakieru BMW M8 Competition folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-m8/6.jpg',
        alt: 'Ochrona lakieru BMW M8 Competition folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesMercedesEQE: Img[] = [
    {
        id: 0,
        href: '../../projects/mercedes-eqe-suv/1.jpg',
        alt: 'Ochrona lakieru Mercedes EQE SUV folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/mercedes-eqe-suv/2.jpg',
        alt: 'Ochrona lakieru Mercedes EQE SUV folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/mercedes-eqe-suv/3.jpg',
        alt: 'Ochrona lakieru Mercedes EQE SUV folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/mercedes-eqe-suv/4.jpg',
        alt: 'Ochrona lakieru Mercedes EQE SUV folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/mercedes-eqe-suv/5.jpg',
        alt: 'Ochrona lakieru Mercedes EQE SUV folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/mercedes-eqe-suv/6.jpg',
        alt: 'Ochrona lakieru Mercedes EQE SUV folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/mercedes-eqe-suv/7.jpg',
        alt: 'Ochrona lakieru Mercedes EQE SUV folią PPF Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWM5: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-m5/1.jpg',
        alt: 'Zmiana koloru auta folią 3M Satin Black - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-m5/2.jpg',
        alt: 'Zmiana koloru auta folią 3M Satin Black - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-m5/3.jpg',
        alt: 'Zmiana koloru auta folią 3M Satin Black - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-m5/4.jpg',
        alt: 'Zmiana koloru auta folią 3M Satin Black - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-m5/5.jpg',
        alt: 'Zmiana koloru auta folią 3M Satin Black - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-m5/6.jpg',
        alt: 'Zmiana koloru auta folią 3M Satin Black - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/bmw-m5/7.jpg',
        alt: 'Zmiana koloru auta folią 3M Satin Black - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesBMWM52: Img[] = [
    {
        id: 0,
        href: '../../projects/bmw-m5-2/1.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/bmw-m5-2/2.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/bmw-m5-2/3.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/bmw-m5-2/4.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/bmw-m5-2/5.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/bmw-m5-2/6.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/bmw-m5-2/7.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 7,
        href: '../../projects/bmw-m5-2/8.jpg',
        alt: 'Zmiana koloru auta folią 3M DM12 Dead Matte - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];

export const ImagesSkodaSuperb: Img[] = [
    {
        id: 0,
        href: '../../projects/skoda-superb/1.jpg',
        alt: 'Ochrona lakieru folią PPF od Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 1,
        href: '../../projects/skoda-superb/2.jpg',
        alt: 'Ochrona lakieru folią PPF od Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 2,
        href: '../../projects/skoda-superb/3.jpg',
        alt: 'Ochrona lakieru folią PPF od Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 3,
        href: '../../projects/skoda-superb/4.jpg',
        alt: 'Ochrona lakieru folią PPF od Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 4,
        href: '../../projects/skoda-superb/5.jpg',
        alt: 'Ochrona lakieru folią PPF od Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 5,
        href: '../../projects/skoda-superb/6.jpg',
        alt: 'Ochrona lakieru folią PPF od Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
    {
        id: 6,
        href: '../../projects/skoda-superb/7.jpg',
        alt: 'Ochrona lakieru folią PPF od Carbonq - WrapTown - Profesjonalne oklejanie aut Poznań Kościan Leszno',
    },
];